import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Dry etching and cleaning tools`}</h4>
    <p>{`Etching and cleaning tools have two main types: dry and wet. Dry etching tools-using gases to etch the substrate-are the most commonly used tool, and are especially necessary for circuit features in advanced chips. Dry etching tools have advantages over wet tools: they are fast and can etch differently depending on the direction of etch ("anisotropic etching"), which enables fine-grained features with complex shapes.`}</p>
    <p>{`The most advanced dry etching tools are called atomic layer etching (ALE) tools. These tools are important for multiple etching applications in the most advanced chips, and are produced by leading U.S., Japanese, and British firms. Though it doesn't produce ALE tools, China’s AMEC produces dry etching tools that are perhaps the most advanced SME tools sold by any Chinese firm. They are used for leading Taiwanese chipmaker TSMC’s 7 and 5 nm nodes, though not for the finest features, such as in complex transistor structures. Recognizing China’s competitiveness in dry etching tools, the United States removed these tools from the Commerce Control List in 2016.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      